exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-index-tsx": () => import("./../../../src/pages/index/index.tsx" /* webpackChunkName: "component---src-pages-index-index-tsx" */),
  "component---src-pages-insights-index-tsx": () => import("./../../../src/pages/insights/index.tsx" /* webpackChunkName: "component---src-pages-insights-index-tsx" */),
  "component---src-pages-maintenance-index-tsx": () => import("./../../../src/pages/maintenance/index.tsx" /* webpackChunkName: "component---src-pages-maintenance-index-tsx" */),
  "component---src-pages-solutions-index-tsx": () => import("./../../../src/pages/solutions/index.tsx" /* webpackChunkName: "component---src-pages-solutions-index-tsx" */),
  "component---src-templates-insights-tsx": () => import("./../../../src/templates/insights.tsx" /* webpackChunkName: "component---src-templates-insights-tsx" */),
  "component---src-templates-policy-tsx": () => import("./../../../src/templates/policy.tsx" /* webpackChunkName: "component---src-templates-policy-tsx" */)
}

